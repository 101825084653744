@use "sass:math";
/*  COLORS  */
$black: #000000;

$blue-lightest: #e8f6ff;
$blue-light: #bfe7ff;
$blue: #14a5ff;
$blue-dark: #0074bd;

$gray-hover: #fafafa;
$gray-lightest: #ebebec;
$gray-lighter: #d1d3d4;
$gray-light: #a7a9ac;
$gray-lightish: #939598;
$gray: #6d6e71;
$gray-darkish: #58595b;
$gray-dark: #2a383d;
$gray-darker: #20292d;
$gray-darkest: #151f23;

$green-light: #e5f8f1;
$green: #00b973;
$green-dark: #397600;
$green-brand: #60953c;
$green-leaf: #63d4aa;

$red-light: #ffe8e9;
$red: #ff1724;
$red-brick: #d7676b;
$red-dark: #cd020d;

$tan: #fcf8cd;
$tan-dark: #999568;
$tan-darkest: #827e53;

$white: #ffffff;
$yellow: #fff475;

$orange: #ffa500;

/*
  BREAKPOINTS:
    extra-small: 0-320 px
          small: 321-480 px
         medium: 481-768 px
          large: 769-1024 px
*/
$breakpoint-extra-small: 320px;
$breakpoint-small: 480px;
$breakpoint-medium: 768px;
$breakpoint-large: 1024px;

/*  CORNERS  */
$corner-radius-small: 3px;
$corner-radius: 6px;
$corner-radius-medium: 13px;
$corner-radius-large: 23px;
$navbar-height: 60px;

/*  TYPOGRAPHY  */
$base-font-size: 18px;
$base-line-height: 25px;
$monospace: monospace;
$sans-serif: 'Nunito', sans-serif;

/*  LAYOUT  */
$padding-xxs: 4px;
$padding-xs: 8px;
$padding-sm: 15px;
$padding-md: 30px;
$padding-lg: 54px;
$padding-xl: 90px;

/*  TRANSITIONS  */
$transition-short: 0.1s;

/* ANNOTATIONS */
$annotation-marker-width: 34px;
$annotation-marker-height: 34px;

/* Unit-less `line-height` for use in components like buttons. */
$line-height-base: 1.428571429 !default;

/* Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc. */
$line-height-computed: math.floor(($base-font-size * $line-height-base)) !default;

//** `<input>` background color
$input-bg: $white !default;

//** Text color for `<input>`s
$input-color: $gray-darker !default;
//** `<input>` border color
$input-border: $gray-lightish !default;

// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius: $corner-radius !default;

//** Border color for inputs on focus
$input-border-focus: $blue !default;

//** Placeholder text color
$input-color-placeholder: $gray-lighter !default;

//** Default `.form-control` height
$input-height-base: ($line-height-computed + $padding-sm + 2) !default;

//** Disabled cursor for form controls and buttons.
$cursor-disabled: not-allowed !default;

//** Colors used for lollipop charts.
$dark-charcoal: #2c2e2f;
$blue-pictone: #40bbea;
$blue-pictone-dark: #2d87aa;
$blue-lighter: #bcdbe5;
$red-lighter: #cc3f4440;
$red-darkest: #cc3f44;
$maroon: #902a2d;
$yellow-dark: #ffba00;
$yellow-darkest: #b88805;
$green-brand-dark: #507236;
