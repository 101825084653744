@use '../../framework/styles/settings' as *;

/*
  Login/Password reset specific styles
*/

@import url(https://fonts.googleapis.com/css?family=Roboto:500&text=Sign%20In%20with%20Google);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:500&text=Sign%20In%20with%20Microsoft);

/* SSO Button Colors */
$google-blue: #1669f2;
$google-hover-blue: #4285f4;
$google-active-blue: #2f71f1;

$microsoft-blue: #0067b8;
$microsoft-hover-blue: #00A4EF;
$microsoft-active-blue: #00A4EF;

.sso-login {
  background-color: $gray-lightest;
  border-radius: $corner-radius;
  /* Override * + * selector for minimal content */
  margin-top: $padding-md !important;
  padding: $padding-sm;
  text-align: center;

  @media screen and (min-width: $breakpoint-medium) {
    padding: $padding-md;
  }

  ul {
    li {
      margin-bottom: $padding-sm;
    }
  }

  &-btn {
    align-items: center;
    display: inline-flex;
    font-size: 80%;
    white-space: nowrap;

    &-icon {
      height: 40px;
      margin-right: 0.75rem;
      width: 40px;
    }

    &-google {
      background-color: $google-blue;
      color: white;
      font-family: 'Roboto', sans-serif;
      padding: 4px 0.75rem 4px 4px;

      &:hover,
      &:focus {
        background: $google-hover-blue;
        color: white;
      }

      &:active {
        background: $google-active-blue;
      }

      .sso-login-btn-icon {
        align-items: center;
        background-color: $white;
        display: flex;
        justify-content: center;
        padding: $padding-xs;
      }
      &.margin-bottom {
        margin-bottom: 8px;
      }
    }

    &-microsoft {
      background-color: $microsoft-blue;
      color: white;
      font-family: 'Nunito', sans-serif;
      padding: 4px 0.75rem 4px 4px;

      &:hover,
      &:focus {
        background: $microsoft-hover-blue;
        color: white;
      }

      &:active {
        background: $microsoft-active-blue;
      }

      .sso-login-btn-icon {
        align-items: center;
        background-color: $white;
        display: flex;
        justify-content: center;
        padding: $padding-xs;
      }
    }
  }
}
